import React from 'react'
import { Link } from "gatsby"

const BlogList = ({posts}) => {

    const newLocal = 4;
    const [load, setLoad] = React.useState(newLocal);

    const handleLoad = () => setLoad(load + 10);

  return (
    <div className="py-120">
      <div className="container">
{/* .slice(1, load) */}
      {posts && posts.map((post, index) => (
          <div data-aos="fade-up" data-aos-delay="150" key={index} className={load >= index ? '' : 'd-none' }>
            <div className="row gx-xxl-5">
                <div className="col-md-4 col-lg-4 blog-small-feature col-xxl-3 mb-md-0 mb-3">
                    {/* <img loading="lazy" src={post.blogCustomPost.smallImage && post.blogCustomPost.smallImage.mediaItemUrl} alt={post.blogCustomPost.smallImage && post.blogCustomPost.smallImage.altText} className="w-100 img-fluid gatsby-image-wrapper" /> */}
                    <picture>
                        <source media="(min-width:575px)" srcSet={post.featuredImage.node?.mediaDetails.sizes[3].sourceUrl} />
                        <img loading="lazy" width="396" height="278" src={post.featuredImage.node?.mediaDetails.sizes[0].sourceUrl} alt={post.featuredImage.node?.altText} className="w-100 gatsby-image-wrapper gatsby-image-wrapper-main-blog img-fluid " />
                    </picture>
                </div>
                <div className="col-md-8 col-lg-8 col-xxl-9">
                    <div className="blog-main-content">
                        <h3><Link aria-label={post.title} to={post.slug} dangerouslySetInnerHTML={{__html: post.title}} /></h3>
                        <div style={{color: '#6B6B6B'}} className="mb-2" dangerouslySetInnerHTML={{__html: post.excerpt.substring(0, 150).substring(0, post.excerpt.substring(0, 150).lastIndexOf(' '))}} />

                        {post.tags.nodes[0] && (
                            <div className="pt-2 flex-wrap d-flex align-items-start">
                                {post.tags.nodes.map((tag) => (
                                    <span className="tag-pill" key={tag.name} dangerouslySetInnerHTML={{__html: tag.name}} />
                                ))}
                            </div>
                        )}

                        <Link aria-label={post.slug} to={post.slug} className="btn text-uppercase btn-link d-inline-flex align-items-center">
                          READ More

                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                          </svg>
                        </Link>
                    </div>
                </div>
            </div>

            <hr className="my-lg-5 my-4" />
          </div>
        ))}


        <div className="text-center" data-aos="fade-up">
            <button type='button' aria-label="Load More" onClick={() => handleLoad()} className="btn text-uppercase btn-link d-inline-flex align-items-center">
            Load More ({posts.length - load > 0 ? posts.length - load : 0})

            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi ms-1 bi-arrow-down-short" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"/>
            </svg>
            </button>
        </div>

      </div>
    </div>
  )
}


export default BlogList;