import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogList from "../components/BlogList"


const Blog = ({data}) => {
    const pageContent = data.allWpPage.nodes[0];
    const pagePosts = data.allWpBlog.nodes;
    // console.log(pagePosts);
    return(
        <Layout>
            <Seo title={pageContent?.seo.title} image="https://dev.alkye.com/wp-content/uploads/2021/12/Sonia-1-1-scaled-1.webp" description={pageContent?.seo.metaDesc} focuskw={pageContent?.seo.focuskw} />    
            
            <div className="page-intro pb-5 page-inner">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12 col-xl-9 col-xxl-10" data-aos="fade-up">
                        <h1 className="title mb-3 mb-md-4 pb-xl-2" dangerouslySetInnerHTML={{__html: pageContent?.title}} />
                        
                        <div dangerouslySetInnerHTML={{__html: pageContent?.content }} />
                    </div>
                    </div>
                </div>
            </div>

            {pagePosts && pagePosts.slice(0,1).map((post, index) => (
                <div className="mb-md-5 mb-3 mt-2 pt-lg-5" key={index}>
                    <div className="container">
                        <div className="blog-main position-relative">
                            <div data-aos="fade-in" data-aos-delay="150">
                                {/* <img loading="lazy" src={post.blogCustomPost.bannerLargeImage && post.blogCustomPost.bannerLargeImage.mediaItemUrl} alt={post.blogCustomPost.bannerLargeImage && post.blogCustomPost.bannerLargeImage.altText} className="w-100 gatsby-image-wrapper img-fluid " /> */}
                                <picture>
                                    <source media="(min-width:1800px)" srcSet={post.featuredImage.node?.sourceUrl} />
                                    <source media="(min-width:1280px)" srcSet={post.featuredImage.node?.mediaDetails.sizes[4].sourceUrl} />
                                    <source media="(min-width:992px)" srcSet={post.featuredImage.node?.mediaDetails.sizes[1].sourceUrl} />
                                    <source media="(min-width:575px)" srcSet={post.featuredImage.node?.mediaDetails.sizes[3].sourceUrl} />
                                    <img loading="lazy" width="1236" height="560" src={post.featuredImage.node?.mediaDetails.sizes[0].sourceUrl} alt={post.featuredImage.node?.altText} className="w-100 gatsby-image-wrapper gatsby-image-wrapper-main-blog img-fluid " />
                                </picture>
                            </div>

                            <div className="blog-main-content bg-primary text-white d-flex flex-column" data-aos="fade-in" data-aos-delay="300">
                                <div>
                                    <h3><Link aria-label={post.slug} to={post.slug} dangerouslySetInnerHTML={{__html: post.title}} /></h3>
                                    <div className="blog-main-content-limit" dangerouslySetInnerHTML={{__html: post.excerpt}} />
                                </div>
                                {post.tags.nodes[0] && (
                                    <div className="mt-auto pt-3 flex-wrap d-flex align-items-start">
                                        {post.tags.nodes.map((tag) => (
                                            <span className="tag-pill" key={tag.name} dangerouslySetInnerHTML={{__html: tag.name}} />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            
            <BlogList posts={pagePosts} />
            
        
        </Layout>
    )
}

export default Blog

export const query = graphql`{
    allWpPage(filter: {slug: {eq: "blog"}}) {
        nodes {
          title
          content
          seo {
            title
            metaDesc
            focuskw
          }
        }
    }
    allWpBlog(sort: {order: DESC, fields: date}) {
        nodes {
          title
          excerpt
          tags {
            nodes {
              name
            }
          }
          slug
          featuredImage {
            node {
              sourceUrl
              altText
              mediaDetails {
                sizes {
                  sourceUrl
                }
              }
            }
          }
        }
    }
  }`